<template>
  <v-card
    tile
    :height="$vuetify.breakpoint.height"
    width="100%"
    class="mx-auto"
    max-width="1000"
  >
    <v-card-title class="secondary white--text rounded-0 d-flex align-start py-1">
      Info Desk | Chat
    </v-card-title>
    <v-card-text
      :style="{ height: $vuetify.breakpoint.height - 165 + 'px' }"
      style="overflow-y: auto"
      class="d-flex flex-column-reverse"
    >
      <span class="text-caption ml-2 green--text name" v-if="last_typed"
        >{{ room_details.helper_user.full_name }} typing...</span
      >
      <v-card v-for="message in room_messages" :key="message.msg_id" flat>
        <v-card v-if="!message.is_socket" flat class="mb-2 pa-1 text-truncate">
          <span :class="[message.class]">{{ message.message }}</span>
        </v-card>
        <div v-else>
          <v-list-item
            v-if="message.attendee_id != myself.attendee_id"
            class=""
          >
            <v-list-item-avatar class="align-self-start mr-2 elevation-1">
              <v-avatar size="40">
                <v-img
                  :src="message.attendee_data.profile_pic"
                  :lazy-src="FRONT_ASSETS + 'placeholder.png'"
                ></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="received-message">
              <v-card color="success" class="flex-none">
                <v-card-text class="white--text pa-2 d-flex flex-column">
                  <span class="text-subtitle-1 chat-message">{{
                    message.message
                  }}</span>
                  <span class="text-caption font-italic align-self-end">{{
                    getTime(message.created_at)
                  }}</span>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :key="message.msg_id">
            <v-list-item-content class="sent-message justify-end">
              <v-card color="primary" class="flex-none">
                <v-card-text class="white--text pa-2 d-flex flex-column">
                  <span class="text-subtitle-1 chat-message">{{
                    message.message
                  }}</span>
                  <span class="text-caption font-italic align-self-start">{{
                    getTime(message.created_at)
                  }}</span>
                </v-card-text>
              </v-card>
            </v-list-item-content>
            <v-list-item-avatar class="align-self-start ml-2 elevation-1">
              <!-- <v-img :src="message.attendee_data.profile_pic" :lazy-src="FRONT_ASSETS + 'placeholder.png'"></v-img> -->
            </v-list-item-avatar>
          </v-list-item>
        </div>
      </v-card>
    </v-card-text>
    <v-card-actions class="pb-0">
      <v-textarea
        placeholder="Type your message"
        rows="2"
        autofocus
        class="px-2"
        filled
        no-resize
        persistent-hint
        hint="Enter to send, Shift+Enter for new line"
        v-model="message"
        @keyup="setUserTyping"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="sendMessage()"
        @keydown.enter.shift.exact="newlineMessage"
        :disabled="!connected || !helper_joined"
      ></v-textarea>
    </v-card-actions>
    <v-btn
      elevation="2"
      color="primary"
      small
      dark
      fab
      fixed
      top
      right
      @click="switchThemeMode"
    >
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import axios from "@/helper/axios";
import WsSubscriptions from "../../helper/websocket";
export default {
  name: "InfoDeskChat",
  data() {
    return {
      connected: false,
      helper_joined: false,
      room_details: {},
      statuses: [],
      subscription: null,
      message: "",
      room_messages: [],
      last_typed: null,
    };
  },
  computed: {
    myself() {
      return JSON.parse(localStorage.getItem("pcm_user"));
    },
  },
  methods: {
    createRoom() {
      axios
        .post("/create-room")
        .then((res) => {
          if (!res.data.status) {
            this.room_messages.unshift({
              message: "Fail to create room",
              class: "red--text",
              is_socket: false,
            });
            return;
          }
          this.room_details = res.data.room;
          this.connected = true;
          this.joinRoom(this.room_details.room_name);
          this.room_messages.unshift({
            message: "Room created",
            class: "green--text",
            is_socket: false,
          });
          this.room_messages.unshift({
            message: "You joined room#" + this.room_details.room_name,
            class: "green--text",
            is_socket: false,
          });

          this.room_messages.unshift({
            message:
              this.room_details.helper_user.full_name +
              " is assigned to this room",
            class: "green--text",
            is_socket: false,
          });
        })
        .catch(() => {});
    },
    joinRoom(roomName) {
      this.subscription = this.$ws.socket.getSubscription(
        `infodesk:${roomName}`
      );
      if (!this.subscription) {
        this.subscription = this.$ws.subscribe(`infodesk:${roomName}`);
        this.$ws.$on(`infodesk:${roomName}|new_message`, this.setSocketMessage);
        this.$ws.$on(`infodesk:${roomName}|room_closed`, this.setRoomClosed);
        this.$ws.$on(
          `infodesk:${roomName}|user_typing`,
          this.setSocketUserTyping
        );
        this.$ws.$on(
          `infodesk:${roomName}|helper_connected`,
          this.setHelperOnline
        );
      }
    },
    setHelperOnline() {
      this.helper_joined = true;
      this.room_messages.unshift({
        message: this.room_details.helper_user.full_name + " joined",
        class: "blue--text",
      });
    },
    setRoomClosed() {
      this.room_messages.unshift({
        message: "room#" + this.room_details.room_name + " is closed",
        class: "red--text",
      });
      this.helper_joined = false;
      this.room_details.is_closed = true;
    },
    setSocketMessage(socketData) {
      socketData.is_socket = true;
      this.room_messages.unshift(socketData);
    },
    switchThemeMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    newlineMessage() {
      this.message = `${this.message}`;
    },
    sendMessage() {
      if (this.message) {
        axios.post("infodesk/add_message", {
          message: this.message,
          room_id: this.room_details.room_name,
        });
      }
      this.message = "";
    },
    setUserTyping(event) {
      if (event.key !== "Enter") {
        this.subscription.emit("userTyping", {
          typed_by: this.myself.attendee_id,
          room_name: this.room_details.room_name,
        });
      }
    },
    setSocketUserTyping(socketData) {
      if (socketData.typed_by != this.myself.attendee_id) {
        this.last_typed = new Date();
        let interval = setInterval(() => {
          if (Date.now() - this.last_typed >= 1500) {
            this.last_typed = null;
          }
          clearInterval(interval);
        }, 1500);
      }
    },
    getTime(time) {
      let msg_date = this.moment(time);
      let today = this.moment(new Date());
      if (msg_date.isSame(today, "day")) {
        return this.moment(time).format("hh:mm A");
      } else {
        return msg_date.format("DD, MMM hh:mm A");
      }
    },
  },
  async created() {
    await WsSubscriptions(this.myself.attendee_token);
  },
  mounted: function() {
    this.room_messages.unshift({
      message: "Creating room for you",
      class: "yellow--text",
      is_socket: false,
    });
    setTimeout(() => {
      this.createRoom();
    }, 2000);
    this.$vuetify.theme.dark = true;
  },
};
</script>

<style scoped>
html {
  overflow-y: auto;
}
.flex-none {
  flex: unset;
}
.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}
.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #80bc40 transparent transparent transparent;
}
</style>
